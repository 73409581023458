import BaseModel from "./base";
import type { _BaseType } from "./_base_type";
import type { ProductType } from "./product";


export type ProductFavoriteType = _BaseType & {
    _ownerid: string
    product: ProductType
    product_id: string | undefined
    addedAt: Date
}

export class ProductFavoriteModel extends BaseModel {
    constructor() {
        super('productFavorites')
        this.required = ['_ownerId']
    }
}